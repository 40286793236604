import BaseTextComponent from "@/components/base/el/BaseTextComponent";

export default {
    name: "BaseSpan",
    mixins: [BaseTextComponent],
    data() {
        return {
            tag: 'span'
        }
    },
}
